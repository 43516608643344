<template>
  <el-dialog :visible.sync="show" title="提示" width="504px" class="dialog-vertical">
    <div class="content">您是否要删除该用户？</div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="onDelete">删除</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //删除的数据id
    deleteId: [String, Number],
    //完成后的回调
    complete: Function,
  },
  data() {
    return {
      loading: false, //删除中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    //删除
    onDelete() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$message.warning("暂无api");
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    color: #303133;
  }
}
</style>
