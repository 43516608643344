<!-- 数据授权弹框 -->
<template>
  <el-dialog
    :visible.sync="show"
    title="数据授权"
    width="504px"
    class="dialog-vertical"
    @open="onOpenDialog"
  >
    <div class="content">
      <el-form
        :model="form"
        ref="ruleForm"
        label-width="85px"
        size="medium"
        class="small-form"
      >
        <!-- 状态 -->
        <el-form-item label="所属架构：">
          <!-- <el-cascader
            v-model="form.selected_ids"
            @change="cascaderChange"
            :options="optionList"
            :props="{
              emitPath: false,
              label: 'name',
              value: 'id',
              children: 'children',
              multiple: true,
            }"
          >
          </el-cascader> -->
          <p class="info">
            <span class="el-icon-info"></span>
            选择想要管理的组织架构，授权后将拥有管理数据的权限
          </p>
          <DataAuthorization
            :data="optionList"
            height="40vh"
            :defaultCheckedKeys="form.selected_ids"
            @check="
              (list) => {
                form.selected_ids = list
              }
            "
          ></DataAuthorization>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="oncConfirm('ruleForm')"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
import { saveAuthData, getAuthData } from "../../api/user";
import DataAuthorization from '@/modules/authority-admin/components/user/DataAuthorization'
export default {
  components: {
    DataAuthorization
  },
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //完成后的回调
    complete: Function,
    // 选中的数组
    ids: Array,
  },
  data() {
    return {
      loading: false, //保存中
      form: {
        // 选中的架构权限ids
        selected_ids: [],
      },
      // 权限架构配置列表
      optionList: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    // 级联选择器改变
    cascaderChange(ids) {
      console.log(ids);
    },
    // 初始化
    initDialog() {
      // 获取权限架构
      getAuthData({ admin_user_id: 0 }).then((res) => {
        this.optionList = res.data.list;
      });
    },
    //打开弹窗回调
    onOpenDialog() {
      this.form.selected_ids = [];
      this.initDialog();
    },

    //确认
    oncConfirm() {
      this.$confirm("确定要覆盖旧的数据授权吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.saveData();
      });
    },
    // 保存权限数据
    saveData() {
      this.loading = true;

      let postData = {
        id: this.ids,
        organization_ids: this.form.selected_ids,
      };
      // 判断有无选择，没选择就是清空所有权限
      if (!this.form.selected_ids.length) {
        // 做特殊处理
        postData.organization_ids = JSON.stringify([]);
      }
      // 保存数据
      saveAuthData(postData)
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
          this.show = false;
          // 执行返回的函数
          this.complete();
        })
        .catch((err) => {
          this.loading = false;
        });
      console.log(postData);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    min-height: 200px;
    color: #303133;

    .small-form {
      .el-form-item--small:last-child {
        margin-bottom: 0;
      }

      p {
        margin-top: 5px;
        // color: #606266;
        font-size: 12px;
        width: 295px;
        line-height: 20px;
      }
      // .info {
      //   color: #606266;
      // }
    }

    .authorize-select {
      & + .authorize-select {
        margin-top: 10px;
      }
    }

    .delete-select {
      margin-left: 5px;
      padding: 2px;
    }

    .add-authorize {
      margin-top: 10px;
    }
  }
}
</style>
