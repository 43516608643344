<template>
  <el-dialog
    :visible.sync="show"
    title="添加用户"
    width="504px"
    class="dialog-vertical"
    @close="resetForm('ruleForm')"
  >
    <div class="content" v-loading="dialogLoading">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="85px"
        size="medium"
        class="small-form"
      >
        <!-- 用户名|账号 -->
        <el-form-item label="账号：" prop="account">
          <el-input v-model="form.account" placeholder="请输入账号"></el-input>
        </el-form-item>
        <!-- 手机号码 -->
        <el-form-item label="手机号码：" prop="phone">
          <el-input
            type="tel"
            v-model="form.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码：" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码">
            <template slot="append">
              <el-button @click="onRandomPass">随机生成</el-button>
            </template>
          </el-input>
        </el-form-item>
        <!-- 所属平台 -->
        <el-form-item label="所属平台：" prop="system">
          <el-select v-model="form.system" placeholder="请选择所属平台">
            <el-option
              v-for="sy in systemsOptions"
              :key="sy.system"
              :label="sy.name"
              :value="sy.system"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item label="状态：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">激活</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 角色授权 -->
        <el-form-item label="角色授权：" prop="role_ids" required>
          <!--          <div v-for="(a,index) in form.role_ids" class="authorize-select" :key="a.id">-->
          <!-- 如果绑定值是数组对象，就需要指定value-key属性 -->
          <el-select
            v-model="form.role_ids"
            multiple
            placeholder="请选择角色授权"
          >
            <el-option
              v-for="avai in availableOptions"
              :key="avai.id"
              :label="avai.name"
              :value="avai.id"
            ></el-option>
          </el-select>
          <!--            <el-button-->
          <!--              v-if="index>0"-->
          <!--              class="delete-select"-->
          <!--              size="mini"-->
          <!--              icon="el-icon-close"-->
          <!--              circle-->
          <!--              @click="onDeleteSelect(index)"-->
          <!--            ></el-button>-->
          <!--          </div>-->
          <!--          <div class="add-authorize">-->
          <!--            <el-button type="primary" @click="onAddAuthorize">添加角色</el-button>-->
          <!--          </div>-->
        </el-form-item>
        <!-- 数据授权 -->
        <el-form-item label="数据授权：" prop="organization_ids">
          <el-select v-show="false" multiple v-model="form.organization_ids"> </el-select>
          <DataAuthorization
            height="20vh"
            :data="AuthOptions"
            :defaultCheckedKeys="form.organization_ids"
            @check="
              (list) => {
                form.organization_ids = list
              }
            "
          ></DataAuthorization>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="oncConfirm('ruleForm')"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
import { userCreate } from '../../api/user'
import { availableList, systemsList } from '../../api/default'
import { randomPassword } from '@/base/utils/tool'
import { getAuthData } from '../../api/user'

import { rsaEncrypt } from '@/base/utils/tool'
import DataAuthorization from '@/modules/authority-admin/components/user/DataAuthorization'
export default {
  components: {
    DataAuthorization,
  },
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    //是否显示
    open: Boolean,
    //完成后的回调
    complete: Function,
  },
  data() {
    return {
      dialogLoading: true,
      loading: false, //保存中
      form: {
        account: '', //用户名|账号
        phone: '', //手机号码
        password: '', //密码
        system: 'admin', //所属平台
        // 角色授权
        role_ids: [],
        status: 1, //状态
        // 选中的架构权限ids
        organization_ids: [],
      },
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur', 'change'],
          },
        ],
        system: [
          { required: true, message: '请选择所属平台', trigger: 'change' },
        ],
        role_ids: [
          //   // {
          //   //   validator: (rule, value, callback) => {
          //   //     console.log(value)
          //   //     if (!value.some((item) => item.val)) {
          //   //       callback(new Error("必须选择一个角色授权"));
          //   //     } else {
          //   //       callback();
          //   //     }
          //   //   },
          //   //   trigger: "change",
          //   // },
          { required: true, message: '请选择角色授权', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选择角色状态', trigger: 'change' },
        ],
        organization_ids: [
          {
            type: 'array',
            required: true,
            message: '请选择数据权限',
            trigger: 'change',
          },
        ],
      },
      systemsOptions: [{ name: '管理后台', system: 'admin' }], //系统平台列表
      availableOptions: [{ id: 1, name: '超级管理员' }], //角色列表
      // 数据授权列表
      AuthOptions: [],
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('changeOpen', val)
      },
    },
    pubKey() {
      return this.$store.getters.pubKey
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        if (!this.pubKey) {
          this.$store.dispatch('GET_PUB_KEY')
        }
      }
    },
  },
  created() {
    //获取系统平台列表
    this.getSystemsList()
    //获取系统平台可用角色列表
    this.getAvailableList()
    // 获取权限架构列表
    this.getAuthOptions()
  },
  methods: {
    // 获取权限架构列表
    getAuthOptions() {
      getAuthData({ admin_user_id: 0 }).then((res) => {
        this.AuthOptions = res.data.list
      })
    },
    //获取系统平台列表
    getSystemsList() {
      systemsList()
        .then((res) => {
          const { data } = res
          this.systemsOptions = data
        })
        .catch((err) => {})
    },
    // 获取系统平台可用角色列表
    getAvailableList() {
      availableList()
        .then((res) => {
          const { data } = res
          this.availableOptions = data
          this.dialogLoading = false
        })
        .catch((err) => {
          this.dialogLoading = false
        })
    },
    //随机密码
    onRandomPass() {
      this.form.password = randomPassword(12, false)
    },
    //添加角色
    onAddAuthorize() {
      const authorize = this.form.role_ids
      const id = authorize[authorize.length - 1].id + 1
      authorize.push({
        id,
        val: '',
      })
    },
    //删除已添加的角色
    onDeleteSelect(index) {
      this.form.role_ids.splice(index, 1)
    },
    //确认
    oncConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          //提交的数据
          const postData = this.getPostData()
          console.log(postData)
          //请求
          userCreate(postData)
            .then((res) => {
              //运行回调
              if (this.complete) this.complete()
              this.$message.success('创建用户成功!')
              //关闭
              this.loading = false
              this.show = false
            })
            .catch((err) => {
              this.loading = false
            })
        }
      })
    },
    //获取提交的数据
    getPostData() {
      return { ...this.form, password: rsaEncrypt(this.form.password) }
    },
    //重置表单
    resetForm(formName) {
      this.form.organization_ids = []
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
      })
      // this.$refs[formName].resetFields()
      // this.form.role_ids = [{ id: "", val: "" }];
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    color: #303133;
    .small-form {
      .el-form-item--small:last-child {
        margin-bottom: 0;
      }
    }
    .authorize-select {
      & + .authorize-select {
        margin-top: 10px;
      }
    }
    .delete-select {
      margin-left: 5px;
      padding: 2px;
    }
    .add-authorize {
      margin-top: 10px;
    }
  }
}
</style>
