<template>
  <div class="list-page">
    <div class="top-btn">
      <el-button size="small" type="primary" @click="openAddUserDialog = true"
        >添加用户</el-button
      >
    </div>
    <list-filter v-model="filterForm" @update="getUserList" />
    <el-button
      style="margin-top: 20px"
      size="small"
      type="primary"
      @click="handleDataAuth"
      >批量数据授权
    </el-button>
    <div class="list-content" style="margin-top: 20px" v-loading="tableLoading">
      <el-table
        :data="tbody"
        style="width: 100%"
        class="thead-light"
        stripe
        @selection-change="handleSelection"
      >
        <el-table-column type="selection" width="55"></el-table-column>

        <el-table-column
          v-for="th in thead"
          :key="th.prop"
          :label="th.label"
          :prop="th.prop"
          :width="th.width"
          :min-width="th.minWidth"
          :fixed="th.fixed"
        >
          <template slot-scope="{ row }">
            <!-- 操作 -->
            <template v-if="th.prop === 'operate'">
              <el-button
                size="small"
                type="text"
                :disabled="row.is_super_admin"
                @click="onUserEdit(row.id)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="text"
                :disabled="row.is_super_admin"
                @click="onDisableEnable(!row.status, row.id)"
                >{{ row.status ? '禁用' : '启用' }}</el-button
              >
              <el-button
                size="small"
                type="text"
                :disabled="row.is_super_admin"
                @click="onDelete(row.id)"
              >
                删除</el-button
              >
            </template>
            <!-- 状态 -->
            <span v-else-if="th.prop === 'status'">
              <ListStatusText
                :text="row.status ? '激活' : '禁用'"
                :type="row.status ? 'success' : 'info'"
              ></ListStatusText>
            </span>
            <!-- 状态 -->
            <span v-else-if="th.prop === 'is_super_admin'">
              <div
                :style="{
                  display: 'flex',
                  justifyContent: 'center',
                }"
              >
                <el-switch
                  disabled
                  v-model="row.is_super_admin"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </div>
            </span>
            <!-- 其他 -->
            <span v-else>{{ row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination
        v-if="initData"
        :total="initData.total"
        :change="onPageChange"
      />
    </div>
    <!-- 删除弹窗 -->
    <delete-dialog v-model="openDeleteDialog" :delete-id="deleteId" />
    <!-- 添加用户弹窗 -->
    <add-dialog v-model="openAddUserDialog" :complete="getUserList" />
    <!-- 批量修改数据权限弹窗 -->
    <authorize-dialog
      :ids="selectId"
      v-model="openAuthorizeDialog"
      :complete="getUserList"
    />
  </div>
</template>
<script>
import ListStatusText from '@/base/components/List/ListStatusText'
import { userList, userUpdateStatus, Delete } from '../api/user'
import DeleteDialog from '../components/user/DeleteDialog'
import AddDialog from '../components/user/AddDialog'
import AuthorizeDialog from '../components/user/AuthorizeDialog'
import Pagination from '@/base/components/Default/Pagination'
import ListFilter from '../components/user/ListFilter.vue'

export default {
  components: {
    DeleteDialog,
    AddDialog,
    Pagination,
    ListFilter,
    AuthorizeDialog,
    ListStatusText,
  },
  data() {
    return {
      tableLoading: false, //获取中
      thead: [
        // { label: "ID", prop: "id", width: 80 },
        { label: '账号', prop: 'account', width: 150 },
        { label: '超级管理员', prop: 'is_super_admin', width: 90 },
        { label: '姓名', prop: 'nickname', width: 150 },
        { label: '手机号', prop: 'phone', width: 120 },
        { label: '邮箱', prop: 'email', width: 200 },
        { label: '角色授权', prop: 'roles_name', width: 250 },
        {
          label: '数据授权',
          prop: 'auth_organization_text',
          width: 400,
          minWidth: 800,
        },
        { label: '最后登录时间', prop: 'last_login_time', width: 180 },
        { label: '登录次数', prop: 'logins' },
        { label: '创建时间', prop: 'create_time', width: 180 },
        { label: '状态', prop: 'status' },
        { label: '操作', prop: 'operate', width: 150, fixed: 'right' },
      ],
      tbody: [],
      openDeleteDialog: false, //显示删除弹窗
      deleteId: '', //删除的数据id
      openAddUserDialog: false, //显示添加用户弹窗
      initData: null, //请求回来的初始数据
      filterForm: {},
      // 选择的列表id 数组
      selectId: [],
      // 数据授权弹框
      openAuthorizeDialog: false,
    }
  },
  created() {
    this.initFilterForm()
    //获取管理用户列表
    this.getUserList()
  },
  methods: {
    // 批量数据授权
    handleDataAuth() {
      console.log(this.selectId.length)
      if (this.selectId.length) {
        console.log(this.selectId)
        this.openAuthorizeDialog = true
      } else {
        this.$message.info('请选择数据项')
      }
    },
    initFilterForm() {
      this.filterForm = Object.assign(
        {},
        {
          create_start_time: -1, // 开始时间
          create_end_time: -1, // 结束时间
          login_start_time: -1,
          login_end_time: -1,
          nickname: '',
          page: 1,
          page_size: 15,
          phone: '',
          email: '',
        }
      )
    },
    //获取管理用户列表
    getUserList(query) {
      this.tableLoading = true
      userList({
        ...this.filterForm,
        ...query,
      })
        .then((res) => {
          const { data } = res
          this.initData = data
          this.tbody = data.data
          console.log(data)
          // console.log(data);
          this.tableLoading = false
        })
        .catch((err) => {
          this.tableLoading = false
        })
    },
    //编辑
    onUserEdit(id) {
      this.$router.push({ name: 'UserEdit', params: { id } })
    },
    //删除
    onDelete(id) {
      this.deleteId = id
      // this.openDeleteDialog = true;

      this.$msgbox
        .confirm('确定删除这条数据？删除后不可恢复，请谨慎操作。', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.tableLoading = true
          Delete({ id: id })
            .then((res) => {
              this.$message.success(res.msg)
              this.getUserList()
            })
            .catch((err) => {
              this.tableLoading = false
            })
        })
        .catch((err) => {
          this.tableLoading = false
        })
    },
    //禁用启用
    onDisableEnable(status, id) {
      this.tableLoading = true
      userUpdateStatus({ status: status ? 1 : 0, id })
        .then((res) => {
          this.$message.success(res.msg)
          this.getUserList()
        })
        .catch((err) => {
          this.tableLoading = false
        })
    },
    //分页发生变化
    onPageChange(pageObj) {
      this.getUserList(pageObj)
    },
    //选中项
    handleSelection(data) {
      let arr = []
      data.forEach((e) => {
        arr.push(e.id)
      })
      console.log(arr)
      this.selectId = arr
    },
  },
}
</script>
